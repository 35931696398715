<template>
  <div class="app-container">
    <div class="app-container__left">
      <v-nav></v-nav>
    </div>
    <div class="app-container__right">
      <div class="app-container__right--body scrollbar userSelect">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import vNav from "@/components/nav";

export default {
  name: "Tabbar",
  components: {
    vNav: vNav,
  },
  data() {
    return { url: window.location.pathname };
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  height: 100%;
  width: 1440px;

  &__left {
    display: flex;
    width: 60px;
    height: 100%;
    overflow: auto;
    /*滚动条样式*/
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &__right {
    flex: 1;
    display: flex;
    width: 0;
    flex-direction: column;

    &--top {
      height: 50px;
      z-index: 10;
    }

    &--body {
      overflow: auto;
      height: 100%;
    }
  }
}
</style>
